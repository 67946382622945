#app {
  max-width: 600px;
}

.styled-svg {
  animation: 2s rotate linear infinite;
  width: 30px;
  height: 30px;
}

.styled-svg path {
  stroke: #0000FF
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
